import i18n from '@/config/i18n'

export const availableIntervals = [
    { key: null, label: '--' },
    { key: 0, label: i18n.t('After every service') },
    ...[ 1, 2, 3, 4, 5, 6, 7 ].map(key => ({ key, label: String(key) }))
]

export const getIntervalLabelByKey = function(key) {
    return availableIntervals.find(
        interval => interval.key === parseInt(key)
    )?.label || '--'
}
