<template>
    <b-message v-if="errors" type="is-danger" has-icon>
        <ul>
            <template v-if="Array.isArray(errors)">
                <li v-for="error in errors" :key="error">
                    {{ $t(error) }}
                </li>
            </template>
            <template v-else>
                <li v-for="key in keys" :key="key">
                    <strong>{{ $t(key) }}: </strong>
                    <span v-for="error in errors[key]" :key="error">
                        {{ $t(error) }}
                    </span>
                </li>
            </template>
        </ul>
    </b-message>
</template>

<script>
    export default {
        name: 'ErrorSection',
        props: {
            errors: {
                type: [ Array, Object ],
                required: false,
                default: () => []
            }
        },
        computed: {
            keys: function () {
                return Object.keys(this.errors)
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
