<template>
    <section class="settings-section">
        <form @submit.prevent="save">
            <b-field :label="$t('Food Groups')" class="padding">
                <b-input v-model="data.food_groups"
                         name="food_groups" />
            </b-field>
            <b-field :label="$t('Supplier')" class="padding">
                <b-input v-model="data.supplier"
                         name="supplier"
                         required="required"
                         :validation-message="$t('Please fill out this field')" />
            </b-field>
            <b-field :label="$t('Order Type')" class="padding">
                <b-input v-model="data.order_type"
                         name="order_type" />
            </b-field>
            <b-field :label="$t('Contact Person')" class="padding">
                <b-input v-model="data.contact_person"
                         name="contact_person" />
            </b-field>
            <b-field :label="$t('Cust. Number')" class="padding">
                <b-input v-model="data.customer_number"
                         name="customer_number" />
            </b-field>
            <b-field :label="$t('Delivery Date')" class="padding">
                <b-autocomplete v-model="data.delivery_date"
                                name="delivery_date"
                                :data="weekdays"
                                open-on-focus />
            </b-field>
            <b-field :label="$t('Supplier_Description')" class="padding">
                <b-input v-model="data.description"
                         name="description" />
            </b-field>
            <ErrorSection :errors="supplier.error" />
            <div class="buttons">
                <b-button type="is-info" @click.prevent="$router.back()">
                    {{ $t('Cancel') }}
                </b-button>
                <b-button native-type="submit" type="is-primary">
                    {{ $t('Save') }}
                </b-button>
            </div>
        </form>
    </section>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import ErrorSection from '../components/ErrorSection'
    import i18n from '@/config/i18n'

    export default {
        name: 'Supplier',
        components: { ErrorSection },
        props: {
            data: {
                type: Object,
                default() {
                    return {
                        food_groups: '',
                        supplier: '',
                        order_type: '',
                        contact_person: '',
                        customer_number: '',
                        delivery_date: '',
                        description: ''
                    }
                }
            }
        },
        data() {
            return {
                weekdays: [
                    i18n.t('Monday'),
                    i18n.t('Tuesday'),
                    i18n.t('Wednesday'),
                    i18n.t('Thursday'),
                    i18n.t('Friday'),
                    i18n.t('Saturday'),
                    i18n.t('Sunday')
                ]
            }
        },
        computed: { ...mapState('settings', [ 'supplier' ]) },
        watch: {
            data() {
                this.setError({
                    type: 'supplier',
                    'error': null
                })
            }
        },
        mounted() {
            this.setError({
                type: 'supplier',
                'error': null
            })
        },
        methods: {
            ...mapActions('settings', [ 'saveSectionItem' ]),
            ...mapMutations('settings', [ 'setError' ]),
            save() {
                this.saveSectionItem({
                    section: 'supplier',
                    data: this.data
                }).then(() => this.$router.back())
            }
        }
    }
</script>

<style scoped lang="scss">

</style>





