<template>
    <section class="settings-section">
        <form @submit.prevent="save">
            <b-field :label="$t('Name')" class="column padding">
                <b-input v-model="data.name"
                         name="name"
                         required="required"
                         :validation-message="$t('Please fill out this field')" />
            </b-field>
            <b-field :label="$t('Times cleaned per week')" class="column padding">
                <b-select v-model="data.cleaning_interval" name="cleaning_interval">
                    <option v-for="option in availableIntervals"
                            :key="option.key"
                            :value="option.key">
                        {{ option.label }}
                    </option>
                </b-select>
            </b-field>
            <b-field :label="$t('Cleaning agent')" class="column padding">
                <b-select v-model="data.cleaning_agent" name="cleaning_interval">
                    <option v-for="option in availableCleaningAgents"
                            :key="option.key"
                            :value="option.key">
                        {{ option.label }}
                    </option>
                </b-select>
            </b-field>
            <b-field :label="$t('Create a frying oil form for this item?')"
                     :message="$t('Documentation obligatory for companies in category A/B, recommended for companies in category C/D.')"
                     class="column padding">
                <b-switch v-model="data.frying_oil"
                          name="frying_oil" />
            </b-field>
            <ErrorSection :errors="inventory.error" />
            <div class="buttons">
                <b-button type="is-info" @click.prevent="$router.back()">
                    {{ $t('Cancel') }}
                </b-button>
                <b-button native-type="submit" type="is-primary">
                    {{ $t('Save') }}
                </b-button>
            </div>
        </form>
    </section>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import ErrorSection from '../components/ErrorSection'
    import { availableIntervals } from '../const'

    export default {
        name: 'Inventory',
        components: { ErrorSection },
        props: {
            data: {
                type: Object,
                default() {
                    return {
                        name: '',
                        cleaning_interval: '',
                        cleaning_agent: '',
                        frying_oil: ''
                    }
                }
            }
        },
        data() {
            return { availableIntervals: availableIntervals }
        },
        computed: {
            ...mapState('settings', [ 'inventory', 'cleaningAgent' ]),
            availableCleaningAgents() {
                return [
                    { key: null, label: '--' },
                    ...this.cleaningAgent.data.map(item => ({ key: item.id, label: String(item.product) }))
                ]
            }
        },
        watch: {
            data() {
                this.setError({
                    type: 'inventory',
                    'error': null
                })
            }
        },
        mounted() {
            this.setError({
                type: 'inventory',
                'error': null
            })

            this.cleaningAgentMap()
        },
        methods: {
            ...mapActions('settings', [ 'saveSectionItem' ]),
            ...mapMutations('settings', [ 'setError' ]),
            save() {
                this.saveSectionItem({ section: 'inventory', data: this.data })
                    .then(() => this.$router.back())
            },
            cleaningAgentMap(){
                if(!this.data.cleaning_agent)
                    return

                const optionIds = Object.keys(this.availableCleaningAgents)

                if(optionIds.indexOf(this.data.cleaning_agent) === -1) {
                    const match = this.availableCleaningAgents.find(item => {
                        return item.label === this.data.cleaning_agent
                    })
                    if(match)
                        this.data.cleaning_agent = match.key
                }
            }
        }
    }
</script>

<style scoped lang="scss">

</style>



