<template>
    <section class="settings-section">
        <form @submit.prevent="save">
            <b-field :label="$t('Last Name and First Name')" class="padding">
                <b-input v-model="data.lastname_and_firstname"
                         required
                         :validation-message="$t('Please fill out this field')"
                         name="lastname_and_firstname" />
            </b-field>
            <b-field :label="$t('Function')" class="padding">
                <b-input v-model="data.function" name="function" />
            </b-field>
            <b-field :label="$t('Email')" class="padding" :message="emailHelpText">
                <b-input v-model="data.email" name="email" type="email" />
            </b-field>
            <b-field :label="$t('Phone Number')" class="padding">
                <b-input v-model="data.phone" name="phone" />
            </b-field>
            <ErrorSection :errors="employee.error" />
            <div class="buttons">
                <b-button type="is-info" @click.prevent="$router.back()">
                    {{ $t('Cancel') }}
                </b-button>
                <b-button native-type="submit" type="is-primary">
                    {{ $t('Save') }}
                </b-button>
            </div>
        </form>
    </section>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import ErrorSection from '../components/ErrorSection'

    export default {
        name: 'Employee',
        components: { ErrorSection },
        props: {
            data: {
                type: Object,
                default() {
                    return {
                        lastname_and_firstname: '',
                        function: '',
                        email: '',
                        phone: ''
                    }
                }
            }
        },
        data() {
            return { emailHelpText: this.$t('Info: Employees without email can not receive notifications.') }
        },
        computed: { ...mapState('settings', [ 'employee' ]) },
        watch: {
            data() {
                this.setError({
                    type: 'employee',
                    'error': null
                })
            }
        },
        mounted() {
            this.setError({
                type: 'employee',
                'error': null
            })
        },
        methods: {
            ...mapActions('settings', [ 'saveSectionItem' ]),
            ...mapMutations('settings', [ 'setError' ]),
            save() {
                this.saveSectionItem({ section: 'employee', data: this.data })
                    .then(() => this.$router.back())
            }
        }
    }
</script>

<style scoped lang="scss">

</style>



