// Register all your form components here
// Note: Make sure all your components have the `name` property. In case of forms,
// this should be a camelcased version of the backened enum label
// ('Business Description' becomes 'BusinessDescription')

import CleaningLocation from './CleaningLocation'
import CleaningAgent from './CleaningAgent'
import CleaningColorConcept from './CleaningColorConcept'
import CuttingBoardColorConcept from './CuttingBoardColorConcept'
import DisposalStation from './DisposalStation'
import Employee from './Employee'
import Inventory from './Inventory'
import StorageLocation from './StorageLocation'
import Supplier from './Supplier'
import TestAndMeasuringDevice from './TestAndMeasuringDevice'

import i18n from '@/config/i18n'

export const sections = [
    {
        title: i18n.t('settings.sections.cleaning_location.title'),
        slug: 'cleaning_location',
        component: CleaningLocation,
        type: 'CleaningLocation',
        order: 4
    },
    {
        title: i18n.t('settings.sections.cleaning_agent.title'),
        slug: 'cleaning_agent',
        component: CleaningAgent,
        type: 'CleaningAgent',
        order: 2
    },
    {
        title: i18n.t('settings.sections.cleaning_color_concept.title'),
        slug: 'cleaning_color_concept',
        component: CleaningColorConcept,
        type: 'CleaningColorConcept',
        order: 5
    },
    {
        title: i18n.t('settings.sections.cutting_board_color_concept.title'),
        slug: 'cutting_board_color_concept',
        component: CuttingBoardColorConcept,
        type: 'CuttingBoardColorConcept',
        order: 9
    },
    {
        title: i18n.t('settings.sections.disposal_station.title'),
        slug: 'disposal_station',
        component: DisposalStation,
        type: 'DisposalStation',
        order: 7
    },
    {
        title: i18n.t('settings.sections.employee.title'),
        slug: 'employee',
        component: Employee,
        type: 'Employee',
        order: 0,
        columnWidths: {
            function: '120px',
            email: '240px',
            phone: '120px'
        }
    },
    {
        title: i18n.t('settings.sections.inventory.title'),
        slug: 'inventory',
        component: Inventory,
        type: 'Inventory',
        order: 3
    },
    {
        title: i18n.t('settings.sections.storage_location.title'),
        slug: 'storage_location',
        component: StorageLocation,
        type: 'StorageLocation',
        order: 6
    },
    {
        title: i18n.t('settings.sections.supplier.title'),
        slug: 'supplier',
        component: Supplier,
        type: 'Supplier',
        order: 1
    },
    {
        title: i18n.t('settings.sections.test_and_measuring_device.title'),
        slug: 'test_and_measuring_device',
        component: TestAndMeasuringDevice,
        type: 'TestAndMeasuringDevice',
        order: 8
    }
].sort((a, b) => a.order - b.order)

export default sections
