<template>
    <section class="settings-section">
        <form class="inventory-table" @submit.prevent="save">
            <fieldset class="fieldset column">
                <b-field :label="$t('Name')">
                    <b-input v-model="data.name"
                             name="name"
                             required="required"
                             :validation-message="$t('Please fill out this field')" />
                </b-field>
                <b-field :label="$t('Location inventory')">
                    <b-taginput
                        v-model="entriesModel"
                        :data="inventoryList"
                        autocomplete
                        field="name"
                        icon="label"
                        open-on-focus
                        :placeholder="$t('Add an inventory item')"
                        class="autocomplete--v2">
                        <template slot-scope="props">
                            <span>{{ props.option.name }}</span>
                        </template>
                        <template slot="empty">
                            <span>{{ $t('There are no inventory items available.') }}</span>
                        </template>
                    </b-taginput>
                </b-field>
            </fieldset>
            <fieldset class="fielset column">
                <p>{{ $t('inventory-location-entries') }}</p>
            </fieldset>
            <fieldset class="fielset column">
                <b-table :data="entriesModel">
                    <template slot-scope="props">
                        <b-table-column :label="$t('What')">
                            {{ props.row.name }}
                        </b-table-column>
                        <b-table-column :label="$t('Cleaning Supply')">
                            {{ props.row.cleaning_agent }}
                        </b-table-column>
                        <b-table-column :label="$t('Frequency')">
                            {{ props.row.cleaning_interval }}
                        </b-table-column>
                    </template>
                </b-table>
            </fieldset>
            <ErrorSection :errors="cleaningLocation.error" />
            <div class="buttons">
                <b-button type="is-info" @click.prevent="$router.back()">
                    {{ $t('Cancel') }}
                </b-button>
                <b-button native-type="submit" type="is-primary">
                    {{ $t('Save') }}
                </b-button>
            </div>
        </form>
    </section>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import ErrorSection from '../components/ErrorSection'

    export default {
        name: 'CleaningLocation',
        components: { ErrorSection },
        props: {
            data: {
                type: Object,
                default() {
                    return {
                        name: '',
                        entries: []
                    }
                }
            }
        },
        data() {
            return { entriesModel: [] }
        },
        computed: {
            ...mapState('settings', [ 'cleaningLocation', 'inventory', 'cleaningAgent' ]),
            inventoryList() {
                return this.inventory.data
            }
        },
        watch: {
            data() {
                this.setError({
                    type: 'cleaningLocation',
                    'error': null
                })
            },
            entriesModel(state) {
                this.data.entries = state.map(item => item.id)
            }
        },
        mounted() {
            this.entriesModel = this.inventoryList.filter(item => this.data.entries.find(entry => entry === item.id))

            this.setError({
                type: 'cleaningLocation',
                'error': null
            })
        },
        methods: {
            ...mapActions('settings', [ 'saveSectionItem' ]),
            ...mapMutations('settings', [ 'setError' ]),
            save(){
                this.saveSectionItem({ section: 'cleaningLocation', data: this.data })
                    .then(() => this.$router.back())
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '@/assets/vars.scss';

    .inventory-table {
        ::v-deep {
            &.b-table {
                .table {
                    tr {
                        td,
                        th {
                            border-bottom-width: 1px;
                        }

                        td {
                            border-right: #d0d0d0 1px solid;
                            font-weight: bold;
                            font-size: 0.8125rem;
                            padding: .5rem;

                            &:last-child {
                                border-right: none;
                            }

                            a {
                                display: block;
                                line-height: 0;

                                &:hover {
                                    svg {
                                        path,
                                        g {
                                            fill: $red;
                                        }
                                    }
                                }
                            }

                            &.action {
                                text-align: center;
                                vertical-align: middle;
                            }
                        }

                        &:hover {
                            svg {
                                g {
                                    fill: $greyish-brown;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>

