<template>
    <section class="settings-section">
        <form @submit.prevent="save">
            <b-field :label="$t('Area')" class="padding">
                <b-input v-model="data.area"
                         name="area"
                         required="required"
                         :validation-message="$t('Please fill out this field')" />
            </b-field>
            <b-field :label="$t('Color')" class="padding">
                <b-input v-model="data.color"
                         name="color"
                         required="required"
                         :validation-message="$t('Please fill out this field')" />
            </b-field>
            <ErrorSection :errors="cleaningColorConcept.error" />
            <div class="buttons">
                <b-button type="is-info" @click.prevent="$router.back()">
                    {{ $t('Cancel') }}
                </b-button>
                <b-button native-type="submit" type="is-primary">
                    {{ $t('Save') }}
                </b-button>
            </div>
        </form>
    </section>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import ErrorSection from '../components/ErrorSection'

    export default {
        name: 'CleaningColorConcept',
        components: { ErrorSection },
        props: {
            data: {
                type: Object,
                default() {
                    return {
                        product: '',
                        area_of_application: '',
                        dosage: ''
                    }
                }
            }
        },
        computed: { ...mapState('settings', [ 'cleaningColorConcept' ]) },
        watch: {
            data() {
                this.setError({
                    type: 'cleaningColorConcept',
                    'error': null
                })
            }
        },
        mounted() {
            this.setError({
                type: 'cleaningColorConcept',
                'error': null
            })
        },
        methods: {
            ...mapActions('settings', [ 'saveSectionItem' ]),
            ...mapMutations('settings', [ 'setError' ]),
            save(){
                this.saveSectionItem({ section: 'cleaningColorConcept', data: this.data })
                    .then(() => this.$router.back())
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
