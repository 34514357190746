import { render, staticRenderFns } from "./DisposalStation.vue?vue&type=template&id=ce25244a&scoped=true"
import script from "./DisposalStation.vue?vue&type=script&lang=js"
export * from "./DisposalStation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce25244a",
  null
  
)

export default component.exports