<template>
    <section class="settings-section settings-section-cutting-board-color-concept">
        <form @submit.prevent="save">
            <b-field :label="$t('Food group')" class="padding">
                <b-input v-model="data.food_group"
                         name="food_group"
                         :validation-message="$t('Please fill out this field')"
                         required="required" />
            </b-field>
            <b-field :label="$t('For which foods')" class="padding">
                <b-input v-model="data.used_food"
                         name="used_food" />
            </b-field>
            <b-field :label="$t('Color')" class="padding">
                <b-input v-model="data.color"
                         name="color"
                         :validation-message="$t('Please fill out this field')"
                         required="required" />
            </b-field>
            <ErrorSection :errors="cuttingBoardColorConcept.error" />
            <div class="buttons">
                <b-button type="is-info" @click.prevent="$router.back()">
                    {{ $t('Cancel') }}
                </b-button>
                <b-button native-type="submit" type="is-primary">
                    {{ $t('Save') }}
                </b-button>
            </div>
        </form>
    </section>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex'
    import ErrorSection from '../components/ErrorSection'


    export default {
        name: 'CuttingBoardColorConcept',
        components: { ErrorSection },
        props: {
            data: {
                type: Object,
                default() {
                    return {
                        food_group: '',
                        used_food: '',
                        color: ''
                    }
                }
            }
        },
        computed: { ...mapState('settings', [ 'cuttingBoardColorConcept' ]) },
        watch: {
            data() {
                this.setError({
                    type: 'cuttingBoardColorConcept',
                    'error': null
                })
            }
        },
        mounted() {
            this.setError({
                type: 'cuttingBoardColorConcept',
                'error': null
            })
        },
        methods: {
            ...mapActions('settings', [ 'saveSectionItem' ]),
            ...mapMutations('settings', [ 'setError' ]),
            save(){
                this.saveSectionItem({ section: 'cuttingBoardColorConcept', data: this.data })
                    .then(() => this.$router.back())
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
